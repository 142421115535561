import React from 'react';
import {
  Block,
  Description,
  Hero,
  Layout,
  Title,
  LinkTo,
  Half,
  Button,
  Notice,
} from '@dragonchain-dev/dragon-kit';
import { Layout as PageLayout, SEO, ContestRow } from '../../../components/_index';

const { IMAGES_URI } = process.env;

const OpenSourceMoney = () => {
  return (
    <PageLayout>
      <SEO
        title="Open Source Money 500,000 DRGN Treasure Hunt"
        description="Watch Open Source Money weekly on the Science Channel for your change at the 500,000 DRGN grand prize!"
        image={`${IMAGES_URI}/banners/contests-open-source-money.png`}
        cardType="summary_large_image"
      />
      <Hero
        type="code"
        illustration={`${IMAGES_URI}/illustrations/open-source-money-hero-c@2x.png`}
        illustrationClass="up-50"
        backgroundImage={`${IMAGES_URI}/texture/open-source-money-hero@2x.jpg`}
      >
        <Title color="white">Open Source Money: Provably Fair 500,000 DRGN Treasure Hunt</Title>
        <Description color="blue-light">
          <p>
            In celebration of Open Source Money&#39;s network television debut, Dragonchain is thrilled to
            announce a provably fair treasure hunt!
          </p>
        </Description>
      </Hero>
      <span id="enter" />
      <Layout dark background="blue-dark">
        <Block center>
          <Title>Weekly Treasure Hunt</Title>
          <p>
            Watch Open Source Money on Science Channel each week and find the answer to a clue about a
            particular scene in the show. Instructions to find clues will be given each week on Super Happy
            Dragon Lucky.
          </p>
        </Block>
        <Block type="full">
          <div className="contest-rows">
            <Notice>
              <p>
                Buy official Open Source Money and Dragonchain merch online.{' '}
                <span role="img" aria-label="shopping">
                  🛍️
                </span>
              </p>
              <Button blank type="link" color="blue" hover="blue-on-white" to="https://dragonchain.store">
                Shop Now
              </Button>
            </Notice>
            <ContestRow name="OSM Grand Prize Winner" winnerKey="emailHash" termsLink="./contest-rules" />
            <ContestRow
              name="OSM Episode 1"
              winnerKey="emailHash"
              prize="Watch SHDL Episode 0x2f"
              termsLink="./contest-rules"
            />
            <ContestRow
              name="OSM Episode 2"
              winnerKey="emailHash"
              prize="Watch SHDL Episode 0x30"
              termsLink="./contest-rules"
            />
            <ContestRow
              name="OSM Episode 3"
              winnerKey="emailHash"
              prize="Watch SHDL Episode 0x31"
              termsLink="./contest-rules"
            />
            <ContestRow
              name="OSM Episode 4"
              winnerKey="emailHash"
              prize="Watch SHDL Episode 0x32"
              termsLink="./contest-rules"
            />
            <ContestRow
              name="OSM Episode 5"
              winnerKey="emailHash"
              prize="Watch SHDL Episode 0x33"
              termsLink="./contest-rules"
            />
          </div>
        </Block>
      </Layout>
      <span id="details" />
      <Layout>
        <Block type="full">
          <div className="blog">
            <div className="blog-article">
              <div className="article-body">
                <h3 className="title">Contest Instructions</h3>
                <ul>
                  <li>
                    For the duration of the 5 episode series, watch Super Happy Dragon Lucky live each
                    Thursday to find out where to look for the weekly clue and how much each weekly prize is
                    (podcast, youtube video, tweet, etc.).
                  </li>
                  <li>Watch Open Source Money on Science Channel each week for the answer.</li>
                  <li>
                    Come back to this contest page after the show. Enter the contest by providing the answer
                    to the weekly clue in the form above.
                  </li>
                  <li>After each episode, a weekly prize winner will be selected as described above.</li>
                  <li>
                    All correct answers, from the start of the contest, are entered into the Grand Prize
                    drawing.
                  </li>
                  <li>
                    Winners have 1 week from the date of the email notification to claim the prize. If the
                    prize is not claimed within 1 week Dragonchain will select a new winner using each
                    successive subsequent block (block 11+).
                  </li>
                </ul>

                <h3 className="title">Contest Guidelines</h3>

                <ul>
                  <li>
                    Participants must be 18 years or older and compliant with local laws and regulations.
                  </li>
                  <li>
                    Participants must not reside in a country listed on the US Dept of Treasury Office of
                    Foreign Assets Control Consolidated Sanctions List.
                  </li>
                  <li>
                    For additional information on privacy, disputes, limitations of liability, general
                    conditions and releases see the&nbsp;
                    <LinkTo type="route" to="./contest-rules">
                      contest rules.
                    </LinkTo>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </Block>
        <Block type="halves">
          <Half
            illustration={{
              center: true,
              middle: true,
              src: `${IMAGES_URI}/brand/jojo.png`,
            }}
          />
          <Half middle>
            <Title tag="h3">Watch live on YouTube</Title>
            <Title>Weekly Schedule</Title>
            <ul>
              <li>Episode 0x2f - July 2 - 11am EDT</li>
              <li>Episode 0x30 - July 9 - 11am EDT</li>
              <li>Episode 0x31 - July 16 - 11am EDT</li>
              <li>Episode 0x32 - July 23 - 11am EDT</li>
              <li>Episode 0x33 - July 30 - 3pm PDT / 6pm EDT</li>
            </ul>
          </Half>
        </Block>
        <Block type="full">
          <div className="blog">
            <div className="blog-article">
              <div className="article-body">
                <h3>
                  Subscribe to our{' '}
                  <LinkTo blank type="link" url="https://www.youtube.com/channel/UC2_StJYNWFrQz2wiL8n6hoA">
                    Youtube Channel
                  </LinkTo>{' '}
                  to watch live*
                </h3>

                <p>
                  *If you can’t make it live, SHDL episodes will also be available to watch anytime on
                  YouTube. You can also listen to the show on your preferred podcast player. They should be
                  available soon after the SHDL livestream.
                </p>
                <p>
                  <LinkTo blank type="link" url="https://open.spotify.com/show/6Ryn1FFgwBf4BN6CBrqbAJ">
                    Spotify
                  </LinkTo>{' '}
                  |{' '}
                  <LinkTo
                    blank
                    type="link"
                    url="https://podcasts.google.com/?feed=aHR0cHM6Ly9mZWVkcy5idXp6c3Byb3V0LmNvbS8xMTUwODg5LnJzcw=="
                  >
                    Google Podcasts
                  </LinkTo>{' '}
                  |{' '}
                  <LinkTo
                    blank
                    type="link"
                    url="https://podcasts.apple.com/us/podcast/super-happy-dragon-lucky/id1518073379"
                  >
                    Apple Podcast
                  </LinkTo>{' '}
                  |{' '}
                  <LinkTo blank type="link" url="https://www.stitcher.com/s?fid=541638&refid=stpr">
                    Stitcher
                  </LinkTo>{' '}
                  |{' '}
                  <LinkTo blank type="link" url="https://www.deezer.com/show/1355692">
                    Deezer
                  </LinkTo>{' '}
                  |{' '}
                  <LinkTo
                    blank
                    type="link"
                    url="https://www.listennotes.com/c/03ff496479c74f428c3eb063b26214de/"
                  >
                    Listen Notes
                  </LinkTo>
                </p>
                <hr />
              </div>
            </div>
          </div>
        </Block>
        <Block type="halves">
          <Half
            illustration={{
              center: true,
              middle: true,
              src: `${IMAGES_URI}/illustrations/open-source-money-hero-c@2x.png`,
            }}
          />
          <Half middle>
            <Title tag="h3">Watch on the Science Channel</Title>
            <Title>Weekly Schedule</Title>
            <ul>
              <li>Episode 1 - July 4 - 10am EDT / 7am PDT</li>
              <li>Episode 2 - July 11 - 10am EDT / 7am PDT</li>
              <li>Episode 3 - July 18 - 10am EDT / 7am PDT</li>
              <li>Episode 4 - July 25 - 10am EDT / 7am PDT</li>
              <li>Episode 5 - August 1 - 10am EDT / 7am PDT</li>
            </ul>
          </Half>
        </Block>
        <Block type="full">
          <div className="blog">
            <div className="blog-article">
              <div className="article-body">
                <p>For all you cord cutters out there, watch on SlingTV or Philo.</p>
                <p>Life’s not fair, but our contests are!</p>
              </div>
            </div>
          </div>
        </Block>
      </Layout>
    </PageLayout>
  );
};

export default OpenSourceMoney;
